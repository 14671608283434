@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

.top-full {
    z-index: 2;
}
body{
  font-family: 'Spectral', serif;
}
.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
